.loader {
  z-index: 1000;
}

.content {
  position: fixed;
  top: calc(var(--header-height) + 54px);
  height: calc(100vh - var(--header-height) - 54px);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileList {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--backdrop-color-loader);
  display: flex;
  align-items: center;
  justify-content: center;
}
