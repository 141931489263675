@import url("./reset.css");
@import url("./common.css");

/* todo: prefix text used for font, for color removed */
:root {
  --background-main: rgba(255, 255, 255, 1);
  --background-secondary: rgba(250, 250, 250, 1);

  --divider-main: rgba(41, 41, 41, 0.1);

  --primary: rgba(70, 128, 216, 1);
  --primary-light: rgba(70, 128, 216, 0.05);
  --text-primary: rgb(0, 0, 0);
  --text-secondary: rgba(255, 255, 255, 1);

  --chip-active: rgba(225, 240, 255, 1);

  --text-title-1: rgb(41, 38, 33); /* #292621 */
  --text-title-2: rgb(21, 21, 21); /* #151515*/

  --gray-1: rgb(242, 242, 242); /*#f2f2f2 */
  --gray-2: rgb(105, 105, 105); /*#696969 */
  --gray-3: rgb(187, 181, 170); /*#bbb5aa*/
  --gray-4: rgb(87, 82, 73); /* #575249 */
  --gray-5: rgba(123, 117, 107, 1); /*#7b756b */

  --red: rgb(248, 0, 0);

  --error-color: rgba(255, 51, 51, 1);

  --font-title-1: 22px;
  --text-body-1: 14px;
  --text-body-2: 16px;
  --text-body-3: 24px;
  --text-body-4: 20px;

  --font-family-primary: "Roboto", sans-serif;

  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;

  --border-radius-main: 12px;
  --border-radius-dialog: 28px;

  --header-height: 64px;
  --content-height: calc(100vh - var(--header-height));

  --filter-panel-width: 320px;
  --results-width: calc(100vw - var(--filter-panel-width));

  --scrollbar-width: 8px;
  --scrollbar-height: 8px;
  --scrollbar-handle-border-radius: 100px;
  --scroll-color: rgba(218, 214, 206, 1);

  --backdrop-color: rgba(48, 45, 39, 0.75);
  --backdrop-color-multi-select: rgba(48, 45, 39, 0.25);
  --backdrop-color-loader: rgba(250, 250, 250, 0.25);
}

h1 {
  color: var(--text-title-2);
  font-size: var(--font-title-1);
  font-weight: var(--font-weight-medium);
  line-height: 127.273%;
  letter-spacing: -0.32px;
}

header {
  height: var(--header-height);
}

body ::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: var(--scrollbar-height);
}

body ::-webkit-scrollbar-track {
  background: var(--background-secondary);
}

body ::-webkit-scrollbar-thumb {
  background: var(--scroll-color);
  border-radius: var(--scrollbar-handle-border-radius);
}

html {
  font-size: var(--text-body-2);
}

body {
  color: var(--text-title-1);
  background-color: var(--background-secondary);
  font-size: 16px;
  font-family: var(--font-family-primary);
  line-height: 1;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

sup,
sub {
  font-size: 60%;
}

sup {
  vertical-align: top;
}

sub {
  vertical-align: bottom;
}

.infinite-scroll-component {
  overflow: visible !important;
}

a {
  text-decoration: none;
}
