.label {
  position: absolute;
  top: 12px;
  left: 16px;
  opacity: 0.42;
}

.formControl legend {
  display: none;
}

.inactive {
  color: var(--gray-3);
}
.checkbox {
  margin-right: 16px;
}