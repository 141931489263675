.Modal__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(48, 45, 39, 0.75);
  flex-grow: 0;
  flex-shrink: 1px;
  z-index: 101;
}
.Modal__modal {
  padding: 28px 24px 24px 24px;
  min-height: 112px;
  min-width: 340px;
  display: flex;
  flex-grow: 0;
  background-color: var(--background-main);
  flex-direction: column;
  position: relative;
  border-radius: var(--border-radius-dialog);
}
.Modal__title {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-normal);
  font-size: var(--text-body-3);
  color: var( --text-title-1);
  margin: 0;
  padding-bottom: 10px;
}

.Modal__content {
  padding-top: 10px;
}

.Modal__content .actions {
  margin-top: 42px;
  display: flex;
  justify-content: flex-end;
}

.Modal__content .actions button {
  padding: 12px 10px;
  margin-left: 9px;
}

.Modal__content .fileInput {
  color: var(--primary);
}