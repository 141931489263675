.nothingFound {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 128px;
}

.nothingFound h2 {
  margin: 1.5rem 0 1rem;

  color: var(--text-title-1);
  text-align: center;
  font-size: var(--font-title-1);
  font-weight: var(--font-weight-medium);
  line-height: 127.273%;
}

.nothingFound p {
  margin: 1.5rem 0 1rem;

  color: var(--gray-4);
  text-align: center;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

.icon {
  width: 60px;
  height: 60px;
  background-color: var(--background-secondary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon svg {
  width: 32px;
  height: 32px;
  color: var(--gray-5);
}

.nothingFound .redirectLink {
  font-weight: var(--font-weight-medium);
  text-decoration: none;
}