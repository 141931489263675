.profileImage {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profileImage .avatar {
    width: 100%;
    height: auto;
    border-radius: 50%;
}

.profileImage .uploadButton,
.profileImage .removeButton {
    letter-spacing: 0.1px;
}

.profileImage .uploadButton {
    margin-top: 6px;
}

.editorActions {
    margin-top: 4px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.editorActions .slider {
    width: 92%;
}

.buttonsWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 34px;
    margin-top: 4px;
}