.Input__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
}
.Input__label {
  display: flex;
  flex: 1;
  color: var(--text-title-1);
}
.Input__input {
  font-family: var(--font-family-primary);
  font-size: var(--text-body-1);
  display: flex;
  flex: 2;
  border: 1px solid rgba(230, 227, 222, 1);
  border-radius: var(--border-radius-main);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--text-title-1);
}

input[type=file].Input__input::file-selector-button {
  color: var(--primary);
  border: none;
  font-family: var(--font-family-primary);
  font-size: var(--text-body-2);
  font-weight: var(--font-weight-medium);
  background: var(--background-main);
  margin-right: 30px;
  cursor: pointer;
}

.Input__wrapper .fileInputError {
  position: absolute;
  top: 50px;
  font-size: var(--text-body-1);
  color: var(--error-color);
}
