.container {
  height: calc(100% - 34px);
  width: var(--results-width);
  margin: 24px 40px 10px 24px;
  padding: 17px 24px;
  background-color: var(--background-main);
  border-radius: var(--border-radius-main);
}

.topPanel {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 16px;
}

.search input[type="text"] {
  padding-left: 0;
}

.topPanel .search {
  margin: 0 16px 0 32px;
}

.search svg {
  cursor: pointer;
}

.topPanel {
  .actionsBlock {
    margin-left: auto;
    min-width: 96px;
    letter-spacing: 0.1px;
  }
  .actionButton {
    margin-left: 5px;
  }
}

.resultsLabel {
  letter-spacing: 0.5px;
  color: var(--gray-5);
  font-size: var(--text-body-1);
}
