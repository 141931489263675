.chipsPanel {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  left: -12px;
  gap: 4px;
  align-items: center;
  margin-bottom: 16px;
  color: var(--text-title-2);
}

.chipsPanel .divider {
  margin: 0 12px;
}

.chipsPanel .chipLabel {
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  letter-spacing: 0.1px;
}
