.textEllipsis {
  display: flex;
  width: 100%;
}

.textEllipsis span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
