.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  background-color: var(--background-main);
  border-bottom: 1px solid var(--divider-main);
}

.pointerNone {
  pointer-events: none;
}
