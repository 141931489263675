.userLogo.userLogo {
  font-weight: var(--font-weight-medium);
  font-size: var(--text-body-1);
  color: var(--text-title-1);
  background-color: var(--gray-1);
}

.pointerNone {
  pointer-events: none;
}

.linkMenuItem span {
  color: var(--text-title-1);
}
