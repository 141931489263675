.profileHeader {
  display: flex;
  padding: 28px 0 24px 0;
  justify-content: space-between;
  border-bottom: 1px solid var(--divider-main);
  position: relative;
}

.profileHeader h1 {
  line-height: 28px;
}

.profileHeader > div > span {
  position: absolute;
  bottom: 0;
  margin-bottom: 8px;
  min-width: 250px;
  font-size: var(--text-body-1);
  letter-spacing: 0.25px;
  font-weight: var(--font-weight-normal);
  color: var(--gray-5);
}
