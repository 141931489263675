.profileContainer {
  position: relative;
}

.profileWrapper {
  width: 1040px;
  padding: 0 24px 24px 24px;
  margin: 0 auto 16px;
  border-radius: var(--border-radius-main);
  background-color: var(--background-main);
}

.profileInfo {
  display: flex;
  padding: 16px 0 16px;
  border-bottom: 1px solid var(--divider-main);
}

.editorWrapper {
  padding: 24px 0 0 0;
}

.pointerEventNone {
  pointer-events: none;
}
