.container {
  box-sizing: border-box;
  max-width: 720px;
  width: 100%;
  min-height: calc(var(--content-height) - 72px);
  margin: 0 auto;
  padding: 24px;
  border-radius: var(--border-radius-main);
  background-color: var(--background-main);
}

.list.list {
  margin: 16px 0 8px;
  padding: 0;
  border-top: 1px solid var(--divider-main);
}

.list li {
  padding-top: 0;
  padding-bottom: 0;
  margin: 8px 0;
}

.list svg {
  color: var(--gray-5);
}

.list li:first-of-type {
  font-size: var(--text-body-2);
  font-weight: var(--font-weight-medium);
  line-height: 150%;
  letter-spacing: 0.15px;
  color: var(--text-title-1);
  margin-top: 16px;
}

.textContent {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.textContent p,
.textContent span {
  font-size: var(--text-body-1);
  font-weight: var(--font-weight-normal);
  line-height: 142.857%;
  letter-spacing: 0.25px;
}

.textContent span {
  display: flex;
  align-items: center;
  color: var(--text-title-1);
}
.textContent span img {
  margin-left: 0;
  height: 16px;
}

.textContent p {
  color: var(--gray-5);
}

button.add {
  margin-left: -12px;
  font-size: var(--text-body-2);
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
}

button.add svg {
  margin-right: 6px;
}
