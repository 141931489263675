.filtersPanel {
  box-sizing: border-box;
  height: 100%;
  width: var(--filter-panel-width);
  min-width: var(--filter-panel-width);
  display: flex;
  flex-direction: column;
  background-color: var(--background-main);
  overflow-x: hidden;
  overflow-y: scroll;
}

.filtersPanel .list {
  padding: 16px;
}

.title.title {
  padding: 4px 8px 4px 16px;
}

.title:hover {
  border-radius: 12px;
}

.title .titleText {
  margin: 0 10px 0 0;
}
.title .titleText span {
  color: var(--gray-5);
  font-size: var(--text-body-1);
  letter-spacing: 0.25px;
  text-transform: uppercase;
}

.title svg {
  color: var(--gray-2);
}

.nestedListWrapper {
  background-color: var(--background-secondary);
  border-radius: var(--border-radius-main);
  padding: 8px 0;
}

.filtersPanel .nestedList {
  max-height: 560px;
  overflow-x: hidden;
}
