:root {
  --form-text-size: 150px;
}

.preContentForm {
  width: 80%;
  padding-left: 24px;
}

.preContentForm sup {
  font-size: inherit;
}

.preContentForm > div {
  min-height: 40px;
}

.formItemText {
  width: var(--form-text-size);
  font-weight: var(--font-weight-medium);
  letter-spacing: 0.15px;
}

.formItemInput {
  width: calc(100% - var(--form-text-size));
}
