.editor-container {
  width: 100%;
  border-radius: 2px;
  position: relative;
  line-height: 20px;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-normal);
  font-size: var(--text-body-2);
  text-align: left;
  border-radius: var(--border-radius-main);
  color: var(--text-title-1);
}

.editor-container.editing {
  padding: 12px 0;
  border: 1px solid rgba(41, 41, 41, 0.1);
}

.editor-inner {
  width: 100%;
  padding: 16px;
  background: var(--background-main);
  position: relative;
}
.editing > .toolbar + .editor-inner {
  padding: 0 16px;
}

.editor-input {
  min-height: 150px;
  resize: none;
  font-size: 15px;
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  outline: 0;
  caret-color: #444;
}

.editor-placeholder {
  color: var(--gray-5);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 18px;
  left: 16px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.editor-text-bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-link {
  color: var(--primary);
  text-decoration: none;
}

.editor-paragraph {
  margin: 0;
  font-size: var(--text-body-2);
  font-family: var(--font-family-primary);
  line-height: 24px;
  letter-spacing: 0.5px;
  position: relative;
}

.editor-paragraph:last-child {
  margin-bottom: 0;
}

.editor-heading-h1,
.editor-heading-h2,
.editor-heading-h3 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-semibold);
  padding: 0;
  letter-spacing: 0.5px;
}

.editor-heading-h1 {
  font-size: var(--text-body-3);
  margin: 24px 0 24px 0;
  line-height: 1;
}

.editor-heading-h2 {
  font-size: var(--text-body-4);
  margin: 20px 0 20px 0;
  line-height: 1;
}

.editor-heading-h3 {
  font-size: var(--text-body-2);
  margin: 16px 0 16px 0;
  line-height: 1;
  letter-spacing: 0.5px;
}

.editor-list-ol {
  margin-left: 16px;
  list-style-type: decimal;
}
.editor-list-ol li,
.editor-list-ul li {
  font-family: var(--font-family-primary);
  font-size: var(--text-body-2);
  line-height: 24px;
  letter-spacing: 0.5px;
}

.editor-list-ul {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: disc;
  font-size: var(--text-body-2);
}

.editor-listitem {
  margin: 8px 32px 8px 32px;
}

.editor-image .focused {
  border: 1px solid var(--primary);
}

pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

pre::-webkit-scrollbar-thumb {
  background: #999;
}

.toolbar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 101;
  display: flex;
  background: var(--background-main);
  padding: 4px;
  vertical-align: middle;
}

.toolbar button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
}

.toolbar button.toolbar-item:disabled {
  cursor: not-allowed;
}

.toolbar button.toolbar-item.spaced {
  margin-right: 2px;
}

.toolbar button.toolbar-item i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 2px;
  vertical-align: -0.25em;
  display: flex;
}

.toolbar button.toolbar-item:disabled i.format {
  opacity: 0.2;
}

.toolbar button.toolbar-item.active {
  background-color: rgba(223, 232, 250, 0.3);
}

.toolbar button.toolbar-item.active i {
  opacity: 1;
}

.toolbar .toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}

.toolbar .divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}

.toolbar select.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 70px;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
}

.toolbar select.code-language {
  text-transform: capitalize;
  width: 130px;
}

.toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  width: 200px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
  height: 20px;
  text-align: left;
}

.toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

.dropdown {
  z-index: 5;
  display: block;
  position: absolute;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-width: 100px;
  min-height: 40px;
  background-color: #fff;
}

.dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  min-width: 268px;
}

.dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.dropdown .item:first-child {
  margin-top: 8px;
}

.dropdown .item:last-child {
  margin-bottom: 8px;
}

.dropdown .item:hover {
  background-color: #eee;
}

.dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  width: 200px;
}

.dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
}

.link-editor {
  position: absolute;
  z-index: 101;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: var(--background-main);
  border-radius: var( --border-radius-main);
  transition: opacity 0.5s;
}

.link-editor .link-input input {
  text-overflow: ellipsis;
}

i.format {
  opacity: 0.6;
}

.icon.paragraph {
  background-image: url(../images/icons/text-paragraph.svg);
}

.icon.h1 {
  background-image: url(../images/icons/type-h1.svg);
}

.icon.h2 {
  background-image: url(../images/icons/type-h2.svg);
}

.icon.h3 {
  background-image: url(../images/icons/type-h3.svg);
}

.icon.bullet-list,
.icon.bullet {
  background-image: url(../images/icons/list-ul.svg);
}

.icon.numbered-list,
.icon.number {
  background-image: url(../images/icons/list-ol.svg);
}

i.bold {
  background-image: url(../images/icons/type-bold.svg);
}

i.italic {
  background-image: url(../images/icons/type-italic.svg);
}

i.underline {
  background-image: url(../images/icons/type-underline.svg);
}

i.link {
  background-image: url(../images/icons/link.svg);
}

i.person-link {
  background-image: url(../images/icons/person-link.svg);
  opacity: 1;
}

i.horizontal-rule {
  background-image: url(../images/icons/horizontal-rule.svg);
  opacity: 1;
}

i.image {
  background-image: url(../images/icons/file-image.svg);
  opacity: 1;
}

i.chevron-down {
  background-image: url(../images/icons/chevron-down.svg);
}

.toolbar i.chevron-down {
  background-color: transparent;
  background-size: contain;
  margin-top: 3px;
  width: 16px;
  height: 16px;
  user-select: none;
}

hr {
  margin: 0;
  border: none;
  border-top: 1px solid var(--divider-main);
}
