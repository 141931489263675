.loginContainer {
  height: var(--content-height);
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginWrapper {
  position: relative;
  width: 546px;
  max-width: calc(100vw - 32px);
  padding: 33px 23px;
  background: var(--background-main);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
}

.loginWrapper > button {
  width: 100%;
  border-radius: 12px;
  padding: 20px;
  margin-top: 2px;
}

.loginWrapper .customInput input {
  padding: 16px 14px;
}
.loginWrapper h1 {
  margin-bottom: 24px;
}
.loginWrapper .customInput {
  margin-bottom: 16px;
}

.errorAlert {
  width: 500px;
  max-width: calc(100vw - 32px);
  position: absolute;
  top: -72px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}
