.table {
  min-width: 500px;
  width: 100%;
  background: var(--background-main);
  font-size: var(--text-body-1);
  font-weight: var(--font-weight-normal);
  line-height: 142.857%;
  letter-spacing: 0.25px;
  position: relative;
}

.table th {
  color: var(--gray-5);
  position: sticky;
  top: 0;
  background: var(--background-main);
}

.table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background: var(--background-main);
}
.table thead tr {
  position: relative;
}
.table thead tr:before,
.table thead tr:after {
  content: "";
  width: 100%;
  border-top: 1px solid #2929291a;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.table thead tr:after {
  top: auto;
  bottom: 0;
}
.table tbody tr:hover {
  background-color: var(--primary-light);
}

.table tbody tr:not(:first-of-type) {
  border-top: 1px solid var(--divider-main);
}

.table td,
.table th {
  vertical-align: middle;
  text-align: start;
  padding: 16px 16px 16px 0;
}

.table th {
  padding: 6px 6px 6px 0;
  letter-spacing: -0.32px;
  line-height: 142.857%;
}

.table td {
  color: var(--text-title-2);
}

.table td:first-of-type {
  padding-left: 4px;
}

.table a {
  color: var(--primary);
  font-weight: var(--font-weight-medium);
  font-size: var(--text-body-2);
  line-height: 150%;
  letter-spacing: 0.15px;
}

.profileName,
.rowFilter {
  display: flex;
  align-items: center;
}

.profileName > div {
  margin-right: 12px;
  width: 64px;
  height: 64px;
}

.rowFilter {
  margin-right: 5px;
}

.countryBlock {
  display: flex;
  align-items: center;
}
