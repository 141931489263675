.infoContainer {
  display: flex;
  width: 80%;
  padding-left: 24px;
  flex-direction: column;
  gap: 6px;
}

.infoWrapper {
  display: flex;
  width: 100%;
  max-width: 100%;
  min-height: 40px;
  align-items: center;
}

.infoLabel,
.infoValue {
  font-size: var(--text-body-2);
  line-height: 1.5;
  color: var(--text-title-1);
}

.infoLabel {
  width: 166px;
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
  letter-spacing: 0.15px;
}

.infoValue {
  letter-spacing: 0.5px;
  max-width: calc(100% - 150px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.marketsWrapper {
  position: relative;
  left: -16px;
}

.pt1 {
  position: relative;
  top: 1px;
}
